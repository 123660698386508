import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Link, navigate } from 'gatsby'
import Currency from 'react-currency-formatter'
import { HrefButton } from '../Button'
import PricingHeadline from '../PricingHeadline'

const PackageCard = styled.div`
  padding: 1.6875rem;
  border: 1px solid grey;
  margin-bottom: 1.6875rem;
  background-color: white;
  color: grey;
  box-shadow: 6px 7px 0px ${props => props.theme.mercury};
`
const PackageCardTitle = styled.h3`
  font-size: 1.75rem;
  margin: 0 0 1.6875rem 0;
  color: ${({ theme }) => theme.jewel} !important;
`

const StyledLink = styled(Link)`
  display: block;
`
const DivorcePackage = props => {
  const {
    title,
    price,
    customPrice,
    children,
    moreDetailsText,
    moreDetailsRoute,
    startButtonText,
    startButtonRoute,
  } = props
  return (
    <PackageCard>
      <PackageCardTitle>{title}</PackageCardTitle>
      {price && (
        <PricingHeadline>{/* <Currency quantity={price} /> */}</PricingHeadline>
      )}
      {/* {customPrice && <PricingHeadline>{customPrice}</PricingHeadline>} */}

      {children}
      <StyledLink to={moreDetailsRoute}>{moreDetailsText}</StyledLink>
      <br />
      <PricingHeadline>
        Contact us to set up your free consultation
      </PricingHeadline>
      <HrefButton href="https://buy.stripe.com/6oEbM61e90Vyfe0bII">
        Get Started
      </HrefButton>
    </PackageCard>
  )
}

DivorcePackage.defaultProps = {
  title: 'Title',
  price: null,
  customPrice: null,
  moreDetailsRoute: '/',
  startButtonText: 'Get Started',
  startButtonRoute: '/',
}
DivorcePackage.propTypes = {
  title: PropTypes.string,
  price: PropTypes.number,
  customPrice: PropTypes.string,
  children: PropTypes.node,
  details: PropTypes.string,
  moreDetailsText: PropTypes.string,
  moreDetailsRoute: PropTypes.string,
  startButtonText: PropTypes.string,
  startButtonRoute: PropTypes.string,
}

export default DivorcePackage
